import { fork, all } from 'redux-saga/effects';
import feedSaga from 'src/lib/redux/sagas/feedSaga';
import yahooScriptSaga from 'src/lib/redux/sagas/yahooScriptSaga';

export default function* rootSaga() {
	yield all([
		fork(feedSaga),
		fork(yahooScriptSaga)
	]);
}
