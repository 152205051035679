import { select, takeEvery } from 'redux-saga/effects';
import { addYahooScript } from 'src/lib/helpers';
import { getCurrentTextAds, getRenderedYahooAds, getYahooProducts, getYahooProps, REPORT_AD_RENDERED } from '../modules/search';
import { getWhoAmI } from '../modules/settings';
import debug from 'src/lib/logger';

export const YAHOO_SCRIPT_SAGA = 'YAHOO_SCRIPT_SAGA';

export function addYahooScriptSaga(yahooAd) {
	return {
		type: YAHOO_SCRIPT_SAGA,
		payload: { yahooAd },
	};
}

/**
 * TODO - we can change it to the functionality run tzur did we setIsDomReady,
 * currently setIsDomReady is for desktop and all products but we can do it for last yahoo product too
 */
let scriptsLoaded = [];
export function* onYahooScript() {
    const yahooProps = yield select(state => getYahooProps(state.search));
	const whoami = yield select(state => getWhoAmI(state.settings));
    const { isHap } = whoami;

	const renderedYahooAds = yield select(state => getRenderedYahooAds(state.search));
	const yahooProducts = yield select(state => getYahooProducts(state.search)) || [];
	const textAds = yield select(state => getCurrentTextAds(state.search)) || [];
	const yahooBeacon = yield select(state => getYahooProps(state.search));
    
	const { ysid } = yahooBeacon;
	const isInitAlreadyCalled = scriptsLoaded.indexOf(ysid) >= 0;
	if (isInitAlreadyCalled) {
		debug('Yahoo Flow: 4* init already called, will not called again', ysid);
	}

    const allYahooAds = [...yahooProducts, ...textAds];
    if (allYahooAds.length > 0 && renderedYahooAds.length >= allYahooAds.length && !isInitAlreadyCalled) {
		debug(`Yahoo Flow: 4 rendered all PLAs and TextAds`, yahooBeacon?.ysid);
        addYahooScript(yahooProps, isHap);
		scriptsLoaded.push(ysid);
    }
}

export default function* root() {
	yield takeEvery(REPORT_AD_RENDERED, onYahooScript);
}
