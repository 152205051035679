// This apiMiddleware will decide if we go to thunk or saga etc..
export default function apiMiddleware() {
	return ({ dispatch, getState }) =>
		next =>
		action => {
			if (typeof action === 'function') {
				return action(dispatch, getState);
			}

			const { saga, promise, types, dispatchBeforeResolve, stateStr, ...rest } = action; // eslint-disable-line no-redeclare
			if (saga) {
				return next(saga());
			}
			if (!promise) {
				return next(action);
			}

			const [REQUEST, SUCCESS, FAILURE] = types;
			next({ ...rest, type: REQUEST });

			let state = {};
			if (stateStr) {
				const innerStates = stateStr.split(',');
				const stateTmp = getState();
				innerStates.forEach(innerState => {
					state[innerState] = stateTmp[innerState];
				});
			} else {
				state = getState();
			}

			const actionPromise = promise({ dispatch, state });
			const dispatchPromised = actionPromise
				.then(
					result => next({ ...rest, payload: result, type: SUCCESS }),
					error => next({ ...rest, error, type: FAILURE })
				)
				.catch(error => {
					console.error('MIDDLEWARE ERROR:', error);
					next({ ...rest, error, type: FAILURE });
				});

			if (dispatchBeforeResolve) {
				return dispatchPromised;
			}

			return actionPromise;
		};
}

// make an action dispatch before resolving the promise
export const dispatchPromised = action => {
	if (action.promise) {
		return {
			...action,
			dispatchBeforeResolve: true,
		};
	}
	return action;
};
