import { createStore, applyMiddleware, combineReducers } from 'redux';
import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import createSagaMiddleware from 'redux-saga';
import apiMiddleware from 'src/lib/redux/apiMiddleware';
import rootSaga from 'src/lib/redux/sagas';
import createReducer from 'src/lib/redux/reducer';
import TrackingReduxManager from '../managers/TrackingReduxManager';
import trackingMiddleware from './trackingMiddleware';

const bindMiddlewares = middleware => {
	if (process.env.NODE_ENV !== 'production') {
		const { composeWithDevTools } = require('redux-devtools-extension');
		return composeWithDevTools(applyMiddleware(...middleware));
	}
	return applyMiddleware(...middleware);
};

const combinedReducer = combineReducers(createReducer());

const globalReducer = (state, action) => {
	if (action.type === HYDRATE) {
		const nextState = {
			...state, // use previous state
			...action.payload, // apply delta from hydration
		};
		return nextState;
	} else {
		return combinedReducer(state, action);
	}
};

function initStore() {
	const sagaMiddleware = createSagaMiddleware();

	const middlewares = [apiMiddleware(), trackingMiddleware(TrackingReduxManager), sagaMiddleware];
	const store = createStore(globalReducer, bindMiddlewares(middlewares));
	store.sagaTask = sagaMiddleware.run(rootSaga);

	return store;
}

export const wrapper = createWrapper(initStore, { debug: false });