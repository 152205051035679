import TrackingManager from 'src/lib/managers/TrackingManager';
import { EVENTS } from 'src/lib/constants/events';
import App from 'next/app';
import React, { Fragment } from 'react';
import Head from 'next/head';
import { bingScript, gtagScript, hapHotjarScript, fsHotjarScript, tiktokScript, fbPixelScript } from 'src/lib/constants/scripts';
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary';
import { IS_CLIENT, IS_DEBUG, SESSION_ID_KEY } from 'src/lib/constants/general';
import { getCookie, removeLocal, setCookie, setMiniStore } from 'src/lib/managers/StorageManager';
import { wrapper } from 'src/lib/redux/store';
import { connect } from 'react-redux';
import { getSSRData, setCurrentPage, setDefaultSettingsData, setLocale, setResolvedUrl, setWhoAmI } from 'src/lib/redux/modules/settings';
import QueryManager from 'src/lib/managers/QueryManager';
import debug from 'src/lib/logger';
import { uuid } from 'src/lib/utils';

// ATF Styles
import 'src/themes/fresh/pages/search/search.scss';
import 'src/components/ProductsGrid/ProductsGrid.scss';
import 'src/components/MobileProductAd/MobileProductAd.scss';
import 'src/components/LoadingOverlay/LoadingOverlay.scss';
import 'src/components/SearchBar/SearchBar.scss';
import 'src/components/FullWidthTextAds/FullWidthTextAds.scss';
import 'src/components/Header/Header.scss';
import 'src/components/TextAd/TextAd.scss';
import 'src/components/FeedPage/FeedPage.scss';
import 'src/scss/fonts.scss';
import './styles.scss';
import {
	getCurrentQuery,
	getIsLoadedProducts,
	getIsLoadingProducts,
	getTomerProps,
	setCurrentQuery,
	setRequestId,
} from 'src/lib/redux/modules/search';
import { resolveHost } from 'src/lib/whoami';
import { DNSMPI, PREFIX_SLUG } from 'src/lib/constants/routes';
import { getCookieBotId } from 'src/lib/helpers';
import { COOKIE_BOT_US, EN_LOCALE } from 'src/lib/constants/locales';
import TestsManager from 'src/lib/managers/TestsManager';
import TrackingReduxManager from 'src/lib/managers/TrackingReduxManager';
import { TIKTOK_CIDS } from 'src/lib/constants/cids';
import { getSnapshotKey } from 'src/lib/snapshots';
// import { isLocalURL } from 'next/dist/next-server/lib/router/router';

// Init error events
if (typeof window !== 'undefined') {
	window.onerror = (message, source, lineno, colno, error) => {
		TrackingManager.trackError(EVENTS.ERROR.UNCAUGHT, message, source, lineno, colno, error);
		return false;
	};

	// Register un-handled promise rejections
	window.addEventListener('unhandledrejection', event => {
		let args = [event];
		if (!(event instanceof Error) && typeof event === 'object') args = event.reason ? [event.reason] : Object.values(event);

		const message = (e => {
			try {
				return `in promise: ${e.message}`;
			} catch (er) {
				return 'in promise';
			}
		})(args[0]);
		TrackingManager.trackError(EVENTS.ERROR.UNCAUGHT, message, ...args);
	});
}

export async function getStaticProps() {
	return {
		props: {}, // will be passed to the page component as props
	};
}

@connect(
	state => ({
		isLoadingProducts: getIsLoadingProducts(state.search),
		isLoadedProducts: getIsLoadedProducts(state.search),
		currentQuery: getCurrentQuery(state.search),
		tomer: getTomerProps(state.search),
		ssrData: getSSRData(state.settings),
	}),
	dispatch => ({
		dispatch,
	})
)
class AppWrapper extends App {
	constructor(props) {
		super(props);
		this.initSettingsData();
	}

	initSettingsData() {
		const { pageProps, currentQuery, router, ssrData } = this.props;
		const { route } = router;
		const { sessionId, host, requestId } = pageProps;
		const { locale } = router;

		const whoami = resolveHost(host);
		const currentPage = router.asPath ? router.asPath.split('?')[0] : router.route;
		const resolvedUrl = router.asPath || router.route;
		const { FULL_DOMAIN } = whoami;
		this.props.dispatch(setLocale({ locale }));
		this.props.dispatch(setResolvedUrl(resolvedUrl));
		this.props.dispatch(setCurrentPage(currentPage));
		this.props.dispatch(setWhoAmI({ whoami })); // !must be in the beginning, do not change the order, must be before extractParsedQuery

		if (requestId) {
			this.props.dispatch(setRequestId({ requestId }));
		}

		const urlParams = QueryManager.extractParsedQuery(router || {}, currentQuery);
		const cookieSessionId = getCookie(SESSION_ID_KEY);
		const uniqueSessionId = sessionId || cookieSessionId || uuid(); // If session id generated from server take it, otherwise generate new one and store it

		this.props.dispatch(setCurrentQuery(urlParams.q || ''));
		this.props.dispatch(
			setDefaultSettingsData({
				urlParams,
				appName: FULL_DOMAIN.toLowerCase(),
				host,
				cid: urlParams.cid,
				currentPage,
				resolvedUrl,
				sessionId: uniqueSessionId,
				geo: locale,
			})
		);

		if (route === PREFIX_SLUG) {
			if (IS_CLIENT) {
				const ssrDataToSnapshot = { ...ssrData };

				setMiniStore({
					vendorPropsByUrl: {
						[getSnapshotKey(ssrDataToSnapshot.urlParams, ssrDataToSnapshot, true)]: {},
					},
				});
				const testObj = TestsManager.getCurrentTest();
				if (Object.keys(testObj).length === 0 && Object.keys(ssrData?.testData || {}).length !== 0) {
					if (ssrData.testData.testId !== null) {
						if (IS_DEBUG) {
							console.log('setttting testData SSR', ssrData.testData);
						}
						TestsManager.setCurrentTest(ssrData.testData);
					}
				}
			} else {
				TestsManager.resetTest();
			}
		}

		setCookie(SESSION_ID_KEY, uniqueSessionId, null, FULL_DOMAIN.toLowerCase());
	}

	addLocaleToBody() {
		const { pageProps, router } = this.props;
		const { locale } = router;
		const { host } = pageProps;
		const whoami = resolveHost(host);
		const { isHap } = whoami;

		if (isHap) {
			document.querySelector('body').classList.add(locale !== EN_LOCALE ? 'hap-eu' : 'hap-en');
		}
	}

	componentDidMount() {
		const { tomer, router } = this.props;
		const { route } = router;
		TrackingManager.sendNavEvents();

		if (route === PREFIX_SLUG) {
			TrackingManager.uniqueTrack(EVENTS.GENERAL.ENTER);
		}

		debug(`qa`, tomer);
		this.initSettingsData();
		this.addLocaleToBody();

		console.log(`v${process.env.APP_VERSION}`);
		removeLocal('sw');

		// TODO - fix
		// document.addEventListener('visibilitychange', () => TrackingManager.trackEvent(document.hidden ? 'Page Blurred' : 'Page Focused'));

		window.onbeforeunload = () => {
			window.scrollTo(0, 0);
		};
	}

	componentDidUpdate = prevProps => {
		if (this.props.currentQuery !== prevProps.currentQuery || this.props.isLoadedProducts) {
			this.initSettingsData();
		}

		if (IS_CLIENT && this.props.isLoadedProducts) {
			TrackingManager.uniqueTrack(EVENTS.GENERAL.ENTER);
		}
	};

	renderCookieBot = () => {
		const { router } = this.props;
		const { locale, route } = router;

		const additionalAttrs = {};
		if (route === DNSMPI) {
			additionalAttrs['data-georegions'] = `{'region':'US','cbid':'${COOKIE_BOT_US}'}`;
			return (
				<script
					async
					id={'Cookiebot'}
					key={'Cookiebot'}
					src={'https://consent.cookiebot.com/uc.js'}
					data-cbid={getCookieBotId(locale)}
					data-blockingmode={'auto'}
					type={'text/javascript'}
					{...additionalAttrs}></script>
			);
		}

		if (locale !== EN_LOCALE) {
			return (
				<script
					async
					id={'Cookiebot'}
					key={'Cookiebot'}
					src={'https://consent.cookiebot.com/uc.js'}
					data-cbid={getCookieBotId(locale)}
					data-blockingmode={'auto'}
					type={'text/javascript'}
					{...additionalAttrs}></script>
			);
		}
	};

	render = () => {
		const { Component, pageProps, router } = this.props;
		const { locale } = router;
		const { host } = pageProps;
		const whoami = resolveHost(host);
		const { isHap } = whoami;
		const cid = TrackingReduxManager.getEntry('cid');

		return (
			<ErrorBoundary>
				<Head>
					<script async src="https://www.googletagmanager.com/gtag/js?id=UA-128483950-1"></script>

					{/* <script
						async={'async'}
						type="text/javascript"
						dangerouslySetInnerHTML={{
							__html: yahooScript,
						}}
					/> */}

					<script
						async={'async'}
						type="text/javascript"
						dangerouslySetInnerHTML={{
							__html: gtagScript,
						}}
					/>

					<script
						async={'async'}
						type="text/javascript"
						dangerouslySetInnerHTML={{
							__html: bingScript,
						}}
					/>

					{+cid === 9480 && (
						<Fragment>
							<script
								async={'async'}
								type="text/javascript"
								dangerouslySetInnerHTML={{
									__html: fbPixelScript,
								}}
							/>
							<noscript
								dangerouslySetInnerHTML={{
									__html: `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=395440856680422&ev=PageView&noscript=1"/>`,
								}}
							/>
						</Fragment>
					)}

					<script
						type="text/javascript"
						dangerouslySetInnerHTML={{
							__html: isHap ? hapHotjarScript : fsHotjarScript,
						}}
					/>

					{TIKTOK_CIDS.includes(TrackingReduxManager.getEntry('cid')) && (
						<script
							type="text/javascript"
							dangerouslySetInnerHTML={{
								__html: tiktokScript,
							}}
						/>
					)}

					{IS_DEBUG ? <script defer src={`https://cdnjs.cloudflare.com/ajax/libs/Faker/3.1.0/faker.min.js`}></script> : null}

					{IS_DEBUG ? (
						<script
							defer
							src={`https://cdnjs.cloudflare.com/ajax/libs/Faker/3.1.0/locales/${locale}/faker.${locale}.min.js`}></script>
					) : null}

					{this.renderCookieBot()}
				</Head>
				{/* <img
					src="https://www.facebook.com/tr?id=196815304716064&amp;ev=PageView"
					height="1"
					width="1"
					style={{ display: 'none' }}></img> */}
				{TrackingReduxManager.getEntry('cid') === '9818' && (
					<img
						src="https://www.facebook.com/tr?id=134523420219564&amp;ev=PageView"
						height="1"
						width="1"
						style={{ display: 'none' }}></img>
				)}
				<Component {...pageProps} />
			</ErrorBoundary>
		);
	};
}

export default wrapper.withRedux(AppWrapper);
