import data from 'src/lib/redux/modules/data';
import sidebar from 'src/lib/redux/modules/sidebar';
import search from 'src/lib/redux/modules/search';
import specialDates from 'src/lib/redux/modules/specialDates';
import category from 'src/lib/redux/modules/category';
import settings from 'src/lib/redux/modules/settings';
import reignnTags from 'src/lib/redux/modules/reignnTags';
import user from 'src/lib/redux/modules/user';

export default function createReducer() {
  return {
    data,
    sidebar,
    search,
    specialDates,
    category,
    settings,
    reignnTags,
    user
  };
}
