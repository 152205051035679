import { Component } from 'react';
import { EVENTS } from 'src/lib/constants/events';
import debug from 'src/lib/logger';
import TrackingManager from 'src/lib/managers/TrackingManager';

export default class ErrorBoundary extends Component {
	componentDidCatch(error, errorInfo) {
		TrackingManager.trackError(EVENTS.ERROR.ERROR_BOUNDARY, error.toString, errorInfo.componentStack);
		debug('Error Boundary', error, errorInfo);
	}

	render() {
		return this.props.children;
	}
}
