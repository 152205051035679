/**
 * N2S
 */
// export const N2SDesktopTop = {
// 	id: 'div-gpt-ad-1577109519377-0',
// 	tagName: 'reignn_similar_N2S_728_top',
// 	slotSize: [728, 90],
// };
// export const N2SDesktopBottom = {
// 	id: 'div-gpt-ad-1577109545621-0',
// 	tagName: 'reignn_similar_N2S_728_Bottom',
// 	slotSize: [728, 90],
// };
// export const N2SDesktopLeft = {
// 	id: 'div-gpt-ad-1577109577136-0',
// 	tagName: 'reignn_similar_N2S_160_Left',
// 	slotSize: [160, 600],
// };
// export const N2SDesktopRight = {
// 	id: 'div-gpt-ad-1577109606146-0',
// 	tagName: 'reignn_similar_N2S_160_right',
// 	slotSize: [160, 600],
// };
// export const N2SDesktopMid = {
// 	id: 'div-gpt-ad-1577109630694-0',
// 	tagName: 'reignn_similar_N2S_300_mid',
// 	slotSize: [300, 250],
// };
// export const N2SMobileTop = {
// 	id: 'div-gpt-ad-1577959003477-0',
// 	tagName: 'reignn_similar_N2S_300*250_mob',
// 	slotSize: [300, 250],
// };
// export const N2SMobileSmall = {
// 	id: 'div-gpt-ad-1577965288496-0',
// 	tagName: 'reignn_similar_N2S_320*50_mob',
// 	slotSize: [320, 50],
// };

/**
 * No Result Page
 */
// Used
const NoResultsPageDesktopTop = {
	id: 'div-gpt-ad-1574610529273-0',
	tagName: 'reignn_similar_nores_dk_728_A',
	slotSize: [728, 90],
};
// Used
const NoResultsPageDesktopMiddle = {
	id: 'div-gpt-ad-1574610611270-0',
	tagName: 'reignn_similar_nores_dk_728_B',
	slotSize: [728, 90],
};
// Used
const NoResultsPageTabletTop = {
	id: 'div-gpt-ad-1574610693915-0',
	tagName: 'reignn_similar_nores_tab_728_A',
	slotSize: [728, 90],
};
// Used
const NoResultsPageTabletMiddle = {
	id: 'div-gpt-ad-1574610780657-0',
	tagName: 'reignn_similar_nores_tab_728_B',
	slotSize: [728, 90],
};
// Used
const NoResultsPageMobileTop = {
	id: 'div-gpt-ad-1574610878353-0',
	tagName: 'reignn_similar_nores_mob_A',
	slotSize: [
		[300, 100],
		[300, 50],
		[320, 50],
		[300, 250],
		[336, 280],
	],
};
// Used
const NoResultsPageMobileBottom = {
	id: 'div-gpt-ad-1574610975073-0',
	tagName: 'reignn_similar_nores_mob_B',
	slotSize: [
		[300, 100],
		[300, 50],
		[320, 50],
		[300, 250],
		[336, 280],
	],
};
// Used
export const NoResultsPageReignnTop = {
	desktop: NoResultsPageDesktopTop,
	tablet: NoResultsPageTabletTop,
	mobile: NoResultsPageMobileTop,
};
// Used
export const NoResultsPageReignnMiddle = {
	desktop: NoResultsPageDesktopMiddle,
	tablet: NoResultsPageTabletMiddle,
	mobile: NoResultsPageMobileBottom,
};

/**
 * Search Page US
// Used
 */
const ReignnSearchPageRustyDesktop = {
	id: 'div-gpt-ad-1572340633563-0',
	tagName: 'reignn_similar_n_dk_728',
	slotSize: [728, 90],
};
// Used
const ReignnSearchPageRustyTablet = {
	id: 'div-gpt-ad-1574610463031-0',
	tagName: 'reignn_similar_tab_728_A',
	slotSize: [728, 90],
};
// Used
export const ReignnSearchPageMobile = {
	id: 'div-gpt-ad-1572189579297-0',
	tagName: 'reignn_similar_mob',
	slotSize: [
		[300, 100],
		[300, 50],
		[320, 50],
		[300, 250],
		[336, 280],
	],
};

// Used
export const ReignnAdSearchPageFresh = {
	mobile: ReignnSearchPageMobile,
};

// Used
export const ReignnAdSearchPageRusty = {
	desktop: ReignnSearchPageRustyDesktop,
	tablet: ReignnSearchPageRustyTablet,
};

/**New Reignn tags for tests */
// Used
export const ReignnTestTagMobileP1 = {
	id: 'div-gpt-ad-1615304514563-0',
	tagName: 'reignn_similar_mob_p1',
	slotSize: [300, 250],
};
export const ReignnTestTagMobileP2 = {
	id: 'div-gpt-ad-1615304612433-0',
	tagName: 'reignn_similar_mob_p2',
	slotSize: [300, 250],
};
export const ReignnTestTagMobileP3 = {
	id: 'div-gpt-ad-1615304654984-0',
	tagName: 'reignn_similar_mob_p3',
	slotSize: [300, 250],
};
export const ReignnTestTagMobileP4 = {
	id: 'div-gpt-ad-1615304693373-0',
	tagName: 'reignn_similar_mob_p4',
	slotSize: [300, 250],
};
export const ReignnTestTagMobileP5 = {
	id: 'div-gpt-ad-1615304723824-0',
	tagName: 'reignn_similar_mob_p5',
	slotSize: [300, 250],
};
export const ReignnTestTagMobileP6 = {
	id: 'div-gpt-ad-1615304753413-0',
	tagName: 'reignn_similar_mob_p6',
	slotSize: [300, 250],
};
export const ReignnTestTagMobileP7 = {
	id: 'div-gpt-ad-1615304782187-0',
	tagName: 'reignn_similar_mob_p7',
	slotSize: [300, 250],
};
export const ReignnTestTagMobileP8 = {
	id: 'div-gpt-ad-1615304808764-0',
	tagName: 'reignn_similar_mob_p8',
	slotSize: [300, 250],
};
export const ReignnTestTagMobileP9 = {
	id: 'div-gpt-ad-1615304838144-0',
	tagName: 'reignn_similar_mob_p9',
	slotSize: [300, 250],
};
export const ReignnTestTagMobileP10 = {
	id: 'div-gpt-ad-1615304866357-0',
	tagName: 'reignn_similar_mob_p10',
	slotSize: [300, 250],
};

/**
 * ReignnTagsTests
 */
//  export const ReignnTagsTestDesktop = [
// 	ReignnTestTagDesktopTn1Tgn,
// 	ReignnTestTagDesktopTn1Tg1,
// 	ReignnTestTagDesktopTn1Tg2,
// 	ReignnTestTagDesktopTn1Tg3,
// 	ReignnTestTagDesktopTn1Tg4,
// ]

// export const ReignnTagsTestTablet = [
// 	ReignnTestTagTabletTn1Tgn,
// 	ReignnTestTagTabletTn1Tg1,
// 	ReignnTestTagTabletTn1Tg2,
// 	ReignnTestTagTabletTn1Tg3,
// 	ReignnTestTagTabletTn1Tg4,
// ]

// export const ReignnTagsTestMobile = [
// 	ReignnTestTagMobileTn1Tgn,
// 	ReignnTestTagMobileTn1Tg1,
// 	ReignnTestTagMobileTn1Tg2,
// 	ReignnTestTagMobileTn1Tg3,
// 	ReignnTestTagMobileTn1Tg4,
// ]

 /// Desktop Tn1
// const ReignnTestTagDesktopTn1Tgn = {
// 	id: 'div-gpt-ad-1579172246321-0',
// 	tagName: 'reignn_similar_dk_728_tn1_tgn',
// 	slotSize: [728, 90],
// }
// const ReignnTestTagDesktopTn1Tg1 = {
// 	id: 'div-gpt-ad-1579170286777-0',
// 	tagName: 'reignn_similar_dk_728_tn1_tg1',
// 	slotSize: [728, 90],
// }
// const ReignnTestTagDesktopTn1Tg2 = {
// 	id: 'div-gpt-ad-1579172089896-0',
// 	tagName: 'reignn_similar_dk_728_tn1_tg2',
// 	slotSize: [728, 90],
// }
// const ReignnTestTagDesktopTn1Tg3 = {
// 	id: 'div-gpt-ad-1579172183999-0',
// 	tagName: 'reignn_similar_dk_728_tn1_tg3',
// 	slotSize: [728, 90],
// }
// const ReignnTestTagDesktopTn1Tg4 = {
// 	id: 'div-gpt-ad-1579172212270-0',
// 	tagName: 'reignn_similar_dk_728_tn1_tg4',
// 	slotSize: [728, 90],
// }

// /// Desktop Tn2
// const ReignnTestTagDesktopTn2Tgn = {
// 	id: 'div-gpt-ad-1579176848316-0',
// 	tagName: 'reignn_similar_dk_728_tn2_tgn',
// 	slotSize: [728, 90],
// }
// const ReignnTestTagDesktopTn2Tg1 = {
// 	id: 'div-gpt-ad-1579176648729-0',
// 	tagName: 'reignn_similar_dk_728_tn2_tg1',
// 	slotSize: [728, 90],
// }
// const ReignnTestTagDesktopTn2Tg2 = {
// 	id: 'div-gpt-ad-1579176680614-0',
// 	tagName: 'reignn_similar_dk_728_tn2_tg2',
// 	slotSize: [728, 90],
// }
// const ReignnTestTagDesktopTn2Tg3 = {
// 	id: 'div-gpt-ad-1579176702521-0',
// 	tagName: 'reignn_similar_dk_728_tn2_tg3',
// 	slotSize: [728, 90],
// }
// const ReignnTestTagDesktopTn2Tg4 = {
// 	id: 'div-gpt-ad-1579176820391-0',
// 	tagName: 'reignn_similar_dk_728_tn2_tg4',
// 	slotSize: [728, 90],
// }

// // Tablet Tn1
// const ReignnTestTagTabletTn1Tgn = {
// 	id: 'div-gpt-ad-1579177075743-0',
// 	tagName: 'reignn_similar_tb_728_tn1_tgn',
// 	slotSize: [728, 90],
// }
// const ReignnTestTagTabletTn1Tg1 = {
// 	id: 'div-gpt-ad-1579176909658-0',
// 	tagName: 'reignn_similar_tb_728_tn1_tg1',
// 	slotSize: [728, 90],
// }
// const ReignnTestTagTabletTn1Tg2 = {
// 	id: 'div-gpt-ad-1579176937537-0',
// 	tagName: 'reignn_similar_tb_728_tn1_tg2',
// 	slotSize: [728, 90],
// }
// const ReignnTestTagTabletTn1Tg3 = {
// 	id: 'div-gpt-ad-1579176962984-0',
// 	tagName: 'reignn_similar_tb_728_tn1_tg3',
// 	slotSize: [728, 90],
// }
// const ReignnTestTagTabletTn1Tg4 = {
// 	id: 'div-gpt-ad-1579176995618-0',
// 	tagName: 'reignn_similar_tb_728_tn1_tg4',
// 	slotSize: [728, 90],
// }

// /// Tablet Tn2
// const ReignnTestTagTabletTn2Tgn = {
// 	id: 'div-gpt-ad-1579177206202-0',
// 	tagName: 'reignn_similar_tb_728_tn2_tgn',
// 	slotSize: [728, 90],
// }
// const ReignnTestTagTabletTn2Tg1 = {
// 	id: 'div-gpt-ad-1579177117823-0',
// 	tagName: 'reignn_similar_tb_728_tn2_tg1',
// 	slotSize: [728, 90],
// }
// const ReignnTestTagTabletTn2Tg2 = {
// 	id: 'div-gpt-ad-1579177141170-0',
// 	tagName: 'reignn_similar_tb_728_tn2_tg2',
// 	slotSize: [728, 90],
// }
// const ReignnTestTagTabletTn2Tg3 = {
// 	id: 'div-gpt-ad-1579177162989-0',
// 	tagName: 'reignn_similar_tb_728_tn2_tg3',
// 	slotSize: [728, 90],
// }
// const ReignnTestTagTabletTn2Tg4 = {
// 	id: 'div-gpt-ad-1579177184853-0',
// 	tagName: 'reignn_similar_tb_728_tn2_tg4',
// 	slotSize: [728, 90],
// }

// Mobile Tn1
export const ReignnTestTagMobileTn1Tgn = {
	id: 'div-gpt-ad-1579177347884-0',
	tagName: 'reignn_similar_mob_300_tn1_tgn',
	slotSize: [300, 250],
};
const ReignnTestTagMobileTn1Tg1 = {
	id: 'div-gpt-ad-1579177243419-0',
	tagName: 'reignn_similar_mob_300_tn1_tg1',
	slotSize: [300, 250],
};
const ReignnTestTagMobileTn1Tg2 = {
	id: 'div-gpt-ad-1579177281454-0',
	tagName: 'reignn_similar_mob_300_tn1_tg2',
	slotSize: [300, 250],
};
const ReignnTestTagMobileTn1Tg3 = {
	id: 'div-gpt-ad-1579177302247-0',
	tagName: 'reignn_similar_mob_300_tn1_tg3',
	slotSize: [300, 250],
};
const ReignnTestTagMobileTn1Tg4 = {
	id: 'div-gpt-ad-1579177325442-0',
	tagName: 'reignn_similar_mob_300_tn1_tg4',
	slotSize: [300, 250],
};

/// Mobile Tn2
const ReignnTestTagMobileTn2Tgn = {
	id: 'div-gpt-ad-1579177477877-0',
	tagName: 'reignn_similar_mob_300_tn2_tgn',
	slotSize: [300, 250],
};
const ReignnTestTagMobileTn2Tg1 = {
	id: 'div-gpt-ad-1579177385911-0',
	tagName: 'reignn_similar_mob_300_tn2_tg1',
	slotSize: [300, 250],
};
const ReignnTestTagMobileTn2Tg2 = {
	id: 'div-gpt-ad-1579177408490-0',
	tagName: 'reignn_similar_mob_300_tn2_tg2',
	slotSize: [300, 250],
};
const ReignnTestTagMobileTn2Tg3 = {
	id: 'div-gpt-ad-1579177428433-0',
	tagName: 'reignn_similar_mob_300_tn2_tg3',
	slotSize: [300, 250],
};
const ReignnTestTagMobileTn2Tg4 = {
	id: 'div-gpt-ad-1579177454439-0',
	tagName: 'reignn_similar_mob_300_tn2_tg4',
	slotSize: [300, 250],
};

export const ReignnTestTagsGroup1 = [
	ReignnTestTagMobileP1,
	ReignnTestTagMobileP2,
	ReignnTestTagMobileP3,
	ReignnTestTagMobileP4,
	ReignnTestTagMobileP5,
	ReignnTestTagMobileP6,
	ReignnTestTagMobileP7,
	ReignnTestTagMobileP8,
	ReignnTestTagMobileP9,
	ReignnTestTagMobileP10,
];

export const ReignnTestTagsGroup2 = [
	ReignnTestTagMobileTn1Tg1,
	ReignnTestTagMobileTn1Tg2,
	ReignnTestTagMobileTn1Tg3,
	ReignnTestTagMobileTn1Tg4,
	ReignnTestTagMobileTn2Tgn,
	ReignnTestTagMobileTn2Tg1,
	ReignnTestTagMobileTn2Tg2,
	ReignnTestTagMobileTn2Tg3,
	ReignnTestTagMobileTn2Tg4
];