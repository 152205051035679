// The errors ignored here are errors that are not a part of the site.
// They are errors here are a part of React and warnings are a part of browsers.
// This array will grow over time because there *are* errors that we can't fix.
const ignoredErrors = [
	'SecurityError: The operation is insecure.', // Firefox issue SSL
	'UET is not defined', // Adblock/tracker error for Bing.

	// Plugins / extensions
	'top.GLOBALS',
	// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html below are things we cannot control
	// and will not break our site - thus we shouldn't log it on sentry
	'originalCreateNotification',
	'canvas.contentDocument',
	// When user uses UIWebView(safari) and tried to highlight then un-highlight text
	'MyApp_RemoveAllHighlights',
	'http://tt.epicplay.com',
	"Can't find variable: ZiteReader",
	'ComboSearch is not defined',
	// iPad webkit bug that doesn't do anything
	'atomicFindClose',
	// Facebook broke
	'fb_xd_fragment',
	// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
	// See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
	'bmi_SafeAddOnload',
	'EBCallBackMessageReceived',
	// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
	'conduitPage',
	// Generic error code from errors outside the security sandbox
	'Script error.',
	// Could add 'Failed to fetch' as it only happens when the user has an
	// adblock or tracker block - it fails to fetch google ads/tracking.
	// google analyticts erros caused by cookie bot
	"Cannot read properties of null (reading 'readyState')",
	"Cannot read property 'readyState' of null",
	"null is not an object (evaluating 'g.readyState')",
	"g is null"
];

const ignoredUrls = [
	//Chrome extensions
	/extensions\//i,
	/^chrome:\/\//i,
	// Firefox extensions
	/^resource:\/\//i,
	//Facebook is blocked on the user's side (country rules or actually blocked)
	/connect\.facebook\.net\/en_US\/all\.js/i,
];

module.exports = {
	ignoredErrors,
	ignoredUrls,
};
