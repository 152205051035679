export const gtagScript = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-128483950-1', { 'groups': 'analytics' });
		        gtag('config', 'AW-983465559');
            `;

//DEPRECATED
// export const pixelScript = `
//   !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?   n.callMethod.apply(n,arguments):n.queue.push(arguments)};
//   if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0];
//   s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');
//     fbq('init', '196815304716064');
//     fbq('track', 'PageView');
//               `;

// export const fbPixelScript = `
//     !function (f, b, e, v, n, t, s) {
//         if (f.fbq) return;
//         n = f.fbq = function () {
//             n.callMethod ?
//                     n.callMethod.apply(n, arguments) : n.queue.push(arguments)
//         };
//         if (!f._fbq) f._fbq = n;
//         n.push = n;
//         n.loaded = !0;
//         n.version = '2.0';
//         n.queue = [];
//         t = b.createElement(e);
//         t.async = !0;
//         t.src = v;
//         s = b.getElementsByTagName(e)[0];
//         s.parentNode.insertBefore(t, s)
//     }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
//     fbq('init', '134523420219564');
// `;

export const fbPixelScript = `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '395440856680422');
fbq('track', 'PageView');`;

export const bingScript = `
    (function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"13009774"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq"); 
`;

export const siteJabberScript = email => {
	return `
    <script>
    (function(d,s,id,c){var js,r=0,t=d.getElementsByTagName(s)[0];js=d.createElement(s);js.id=id;js.src='https://static.sitejabber.com/js/widget.min.1621367226.js';js.onload=js.onreadystatechange=function(){if(!r&&(!this.readyState||this.readyState[0]=='c')){r=1;c();}};t.parentNode.insertBefore(js,t);}(document,'script','sj-widget',
      function(){
        new STJR.InstantFeedbackWidget({
          id:'InstantFeedbackWidget',
          url:'findsimilar.com',
          language:'en',
          user:{first_name:'',last_name:'',email:'${email}'}).render();
      }));
    </script>
    `;
};

export const fsHotjarScript = `
(function(h,o,t,j,a,r){
  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  h._hjSettings={hjid:1266602,hjsv:6};
  a=o.getElementsByTagName('head')[0];
  r=o.createElement('script');r.async=1;
  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  `;

export const hapHotjarScript = `
(function(h,o,t,j,a,r){
  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  h._hjSettings={hjid:2749580,hjsv:6};
  a=o.getElementsByTagName('head')[0];
  r=o.createElement('script');r.async=1;
  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
`;

// export const yahooScript = `
// (function(w,d,t,x,m,l,p){w.xmlpDeferPageLoad=true;w['XMLPlusObject']=m;w[m]=w[m]||function(){(w[m].q=w[m].q||[]).push(arguments)},w[m].l=1*new Date();l=d.createElement(t),p=d.getElementsByTagName(t)[0];l.type="text/javascript";l.async=1;l.defer=1;l.src=x;p.parentNode.insertBefore(l,p)})(window,document,'script','https://s.yimg.com/ds/scripts/xmlp.js','xmlp');
// `;

export const yahooScript = `
(function(w,d,t,x,m,l,p){w.selectTierDeferPageLoad=true;w['XMLPlusSTObject']=m;w[m]=w[m]||function(){(w[m].q=w[m].q||[]).push(arguments)},w[m].l=1*new Date();l=d.createElement(t),p=d.getElementsByTagName(t)[0];l.type="text/javascript";l.async=1;l.defer=1;l.src=x;p.parentNode.insertBefore(l,p)})(window,document,'script','https://s.yimg.com/ds/scripts/selectTier.js','selectTier')`;

export const tiktokScript = `
!function (w, d, t) {
  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
  ttq.load('CA1O4F3C77U70J01702G');
  ttq.page();
}(window, document, 'ttq');`;
