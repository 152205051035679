import { fetchSpecialDatesDataApi } from 'src/lib/api';

export const FETCH_SPECIAL_DATES_DATA = 'FETCH_SPECIAL_DATES_DATA';
export const FETCH_SPECIAL_DATES_DATA_SUCCESS = 'FETCH_SPECIAL_DATES_DATA_SUCCESS';
export const FETCH_SPECIAL_DATES_DATA_FAIL = 'FETCH_SPECIAL_DATES_DATA_FAIL';

// REDUCERS
const initialState = {
	specialDatesData: null,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_SPECIAL_DATES_DATA:
			return {
				...state,
				loadingSpecialDatesData: true,
			};
		case FETCH_SPECIAL_DATES_DATA_SUCCESS:
			return {
				...state,
				loadingSpecialDatesData: false,
				isSpecialDatesDataSuccess: true,
				specialDatesData: action.payload,
			};
		case FETCH_SPECIAL_DATES_DATA_FAIL:
			return {
				...state,
				loadingSpecialDatesData: false,
				isSpecialDatesDataSuccess: false,
				specialDatesData: action.error,
			};
		default:
			return state;
	}
}

// GETTERS
export const getSpecialDatesData = state => state.specialDatesData;

// LP GETTERS
export const getMainSectionTitle = state =>
	(state.specialDatesData &&
		state.specialDatesData.mainsection &&
		state.specialDatesData.mainsection[0] &&
		state.specialDatesData.mainsection[0].title) ||
	'';
export const getMainSectionColor = state =>
	(state.specialDatesData &&
		state.specialDatesData.mainsection &&
		state.specialDatesData.mainsection[0] &&
		state.specialDatesData.mainsection[0].color) ||
	'#CD2037';
export const getSaleUpTo = state =>
	(state.specialDatesData &&
		state.specialDatesData.mainsection &&
		state.specialDatesData.mainsection[0] &&
		state.specialDatesData.mainsection[0].saleupto) ||
	'';
export const getSaleUntil = state =>
	(state.specialDatesData &&
		state.specialDatesData.mainsection &&
		state.specialDatesData.mainsection[0] &&
		state.specialDatesData.mainsection[0].saleuntil) ||
	'';
export const getCategoriesSettings = state =>
	(state.specialDatesData &&
		state.specialDatesData.categoriessettings &&
		state.specialDatesData.categoriessettings[0] &&
		state.specialDatesData.categoriessettings[0]) ||
	{};
export const getCategoriesData = state =>
	(state.specialDatesData && state.specialDatesData.categoriesdata && state.specialDatesData.categoriesdata) || [];
export const getBackgroundImage = state => state.specialDatesData && state.specialDatesData.backgroundimage;
export const getBottomSectionSettings = state =>
	(state.specialDatesData &&
		state.specialDatesData.bottomsectionsettings &&
		state.specialDatesData.bottomsectionsettings[0] &&
		state.specialDatesData.bottomsectionsettings[0]) ||
	{};
export const getImages = state =>
	(state.specialDatesData && state.specialDatesData.images && state.specialDatesData.images[0] && state.specialDatesData.images[0]) || {};

//ACTIONS

// ACTIONS WITH PROMISES
export const fetchSpecialDatesData = (queryParams, transformData = null) => ({
	types: [FETCH_SPECIAL_DATES_DATA, FETCH_SPECIAL_DATES_DATA_SUCCESS, FETCH_SPECIAL_DATES_DATA_FAIL],
	promise: () =>
		fetchSpecialDatesDataApi(queryParams).then(data => {
			if (transformData) {
				return transformData(data);
			}
			return data;
		}),
});
