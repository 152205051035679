import { SET_REQUEST_ID } from './modules/search';
import { SET_DEFAULT_SETTINGS_DATA, SET_LOCALE, SET_SSR_DATA, SET_WHO_AM_I } from './modules/settings';

const catchTypes = [SET_SSR_DATA, SET_DEFAULT_SETTINGS_DATA, SET_WHO_AM_I, SET_LOCALE, SET_REQUEST_ID];

export default function trackingMiddleware(trackingReduxManager) {
	return () => next => action => {
		if (catchTypes.indexOf(action.type) >= 0) {
			// {
			// SET_DEFAULT_SETTINGS_DATA
			// 		urlParams,
			// 		appName,
			//		host
			// 		cid,
			// 		currentPage
			// 		sessionId,
			// 		geo,
			//
			// SET_WHO_AM_I
			//	whoami
			//		APP_NAME
			//		DOMAIN_NAME
			//		FULL_DOMAIN
			//		isHap
			//		TLD
			//
			// SET_SSR_DATA
			//		urlParams,
			//		userAgent,
			//		referrer
			//		appName,
			//		host
			//		cid,
			//		currentPage,
			//		sessionId,
			//      resolvedUrl,
			//		geo
			//		requestId
			//	SET_REQUEST_ID
			//		requestId
			// }
			trackingReduxManager.setData(action.payload); // action.payload will be the same as ssrData in store.settings
		}
		return next(action);
	};
}