import { ReignnAdSearchPageRusty, ReignnAdSearchPageFresh } from 'src/lib/constants/reignnTags';
export const SET_RUSTY_REIGNN_TAGS = 'SET_RUSTY_REIGNN_TAGS';
export const SET_FRESH_REIGNN_TAGS = 'SET_FRESH_REIGNN_TAGS';

// REDUCERS
const initialState = {
	rustyReignnTags: ReignnAdSearchPageRusty,
	freshReignnTags: ReignnAdSearchPageFresh,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SET_RUSTY_REIGNN_TAGS:
			return {
				...state,
				rustyReignnTags: action.payload,
			};
		case SET_FRESH_REIGNN_TAGS:
			return {
				...state,
				freshReignnTags: action.payload,
			};
		default:
			return state;
	}
}

// GETTERS
export const getRustyReignnTags = state => state.rustyReignnTags;
export const getFreshReignnTags = state => state.freshReignnTags;

//ACTIONS
export const setRustyReignnTags = payload => ({ type: SET_RUSTY_REIGNN_TAGS, payload });
export const setFreshReignnTags = payload => ({ type: SET_FRESH_REIGNN_TAGS, payload });
