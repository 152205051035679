import * as Sentry from '@sentry/browser';
import { IS_PROD } from 'src/lib/constants/general';
import { ignoredErrors, ignoredUrls } from 'src/lib/sentryIgnore';

// import debug from 'src/lib/logger';

const IS_DEBUG = typeof location !== 'undefined' && location.href.includes('tomer=1');
const sentryDSN = 'https://f2438429295847b4853541b774591f0b@sentry.io/1371089';

if (IS_PROD) {
	process.env.SENTRY_RELEASE = process.env.APP_VERSION;
	Sentry.init({
		environment: process.env.ENV,
		dsn: sentryDSN,
		ignoreErrors: ignoredErrors,
		ignoreUrls: ignoredUrls,
		beforeSend(event, hint) {
			const error = hint.originalException;
			if (error && error.message && IS_DEBUG) {
				console.log('DEV DEBUG sentry error.message', error.message);
			}
			if (error && error.message === "Cannot read properties of null (reading 'readyState')") {
				return null;
			}
			return event;
		},
		// ...
		// Note: if you want to override the automatic release value, do not set a
		// `release` value here - use the environment variable `SENTRY_RELEASE`, so
		// that it will also get attached to your source maps
	});
}
